import { createReducer } from 'typesafe-actions';
import {
  IGetTotalForFilteredInvoiceItemsResponse,
  IRetailersForUnpaidOrders,
} from '../../../api/orders.api';
import {
  IOrderList,
  IOrderListFilter,
  IOrdersSummary,
  IUnpaidOrdersSummary,
} from '../../../interfaces/IOrder';
import { IPayoutList } from '../../../interfaces/IPayout';
import { RootActions } from '../../actions';
import {
  acceptCancellationRequestAction,
  cancelLineItemsAction,
  declineCancellationRequestAction,
  hideDecliningCancellationToastAction,
  hideAcceptingCancellationToastAction,
  refundOrderAction,
  setOrderDetailsAction,
  setOrderListFilterAction,
  setOrdersListAction,
  setOrdersListFetchingAction,
  setOrdersSummaryAction,
  setOrdersSummaryFetchingAction,
  setPayoutFetchingAction,
  setPayoutListAction,
  setRetailersForUnpaidOrdersAction,
  setUnpaidOrdersListAction,
  setUnpaidOrdersListFetchingAction,
  setUnpaidOrdersSummaryAction,
  setUnpaidOrdersTotalDetailsAction,
} from './orders.actions';

export interface IOrdersReducer {
  orderList: IOrderList;
  unpaidOrderList: IOrderList;
  payoutList: IPayoutList;
  filters: IOrderListFilter;
  totalDetails: IGetTotalForFilteredInvoiceItemsResponse;
  ordersSummary: IOrdersSummary;
  retailers: IRetailersForUnpaidOrders[];
  unpaidOrdersSummary: IUnpaidOrdersSummary;
  fetchingOrdersList: boolean;
  fetchingUnpaidOrdersList: boolean;
  fetchingOrdersSummary: boolean;
  fetchingCancellation: boolean;
  fetchingPayoutList: boolean;
  acceptingCancellation: boolean;
  decliningCancellation: boolean;
}

const initialState: IOrdersReducer = {
  orderList: {
    total: -1,
    orders: [],
    details: {},
  },
  unpaidOrderList: {
    total: -1,
    orders: [],
    details: {},
  },
  payoutList: {
    payouts: [],
    total: 0,
  },
  filters: {},
  retailers: [],
  totalDetails: {
    total: 0,
    ids: [],
  },
  ordersSummary: {
    totalOrders: 0,
    unfulfilledOrders: 0,
    totalEarned: 0,
    cancellationRequests: 0,
  },
  unpaidOrdersSummary: {
    totalOrders: 0,
    totalNotPaidOut: 0,
  },
  fetchingOrdersList: false,
  fetchingUnpaidOrdersList: false,
  fetchingOrdersSummary: false,
  fetchingCancellation: false,
  fetchingPayoutList: false,
  acceptingCancellation: false,
  decliningCancellation: false,
};

export const orderReducer = createReducer<IOrdersReducer, RootActions>(initialState)
  .handleAction(setOrdersListFetchingAction, (state, { payload }) => {
    return { ...state, fetchingOrdersList: payload };
  })
  .handleAction(setUnpaidOrdersListFetchingAction, (state, { payload }) => {
    return { ...state, fetchingUnpaidOrdersList: payload };
  })
  .handleAction(setOrdersSummaryFetchingAction, (state, { payload }) => {
    return { ...state, fetchingOrdersSummary: payload };
  })
  .handleAction(setOrdersSummaryAction, (state, { payload }) => ({
    ...state,
    ordersSummary: payload,
  }))
  .handleAction(setUnpaidOrdersSummaryAction, (state, { payload }) => ({
    ...state,
    unpaidOrdersSummary: payload,
  }))
  .handleAction(acceptCancellationRequestAction, (state) => ({
    ...state,
    acceptingCancellation: true,
  }))
  .handleAction(hideAcceptingCancellationToastAction, (state) => ({
    ...state,
    acceptingCancellation: false,
  }))
  .handleAction(declineCancellationRequestAction, (state) => ({
    ...state,
    decliningCancellation: true,
  }))
  .handleAction(hideDecliningCancellationToastAction, (state) => ({
    ...state,
    decliningCancellation: false,
  }))
  .handleAction(setOrdersListAction, (state, { payload }) => ({
    ...state,
    orderList: {
      ...payload,
      details: state.orderList.details,
    },
  }))
  .handleAction(setUnpaidOrdersListAction, (state, { payload }) => ({
    ...state,
    unpaidOrderList: {
      ...payload,
      details: state.unpaidOrderList.details,
    },
  }))
  .handleAction(setOrderListFilterAction, (state, { payload }) => ({
    ...state,
    filters: payload,
  }))
  .handleAction(setRetailersForUnpaidOrdersAction, (state, { payload }) => ({
    ...state,
    retailers: [...payload, { value: 'Crowdship', label: 'Crowdship' }],
  }))
  .handleAction(setOrderDetailsAction, (state, { payload: { details, orderId } }) => ({
    ...state,
    orderList: {
      ...state.orderList,
      details: {
        ...state.orderList.details,
        [orderId]: details,
      },
    },
    acceptingCancellation: false,
    fetchingCancellation: false,
    decliningCancellation: false,
  }))
  .handleAction(setUnpaidOrdersTotalDetailsAction, (state, { payload }) => ({
    ...state,
    totalDetails: payload,
  }))
  .handleAction(setPayoutListAction, (state, { payload }) => ({
    ...state,
    payoutList: payload,
  }))
  .handleAction(setPayoutFetchingAction, (state, { payload }) => ({
    ...state,
    fetchingPayoutList: payload,
  }))
  .handleAction(cancelLineItemsAction, (state) => ({
    ...state,
    fetchingCancellation: true,
  }))
  .handleAction(refundOrderAction, (state) => ({
    ...state,
    fetchingCancellation: true,
  }));
