import { createAction } from 'typesafe-actions';
import { Paginated } from '../../../../../core/helpers/generic.helper';
import { IMarketplaceImportFilter } from '../../../../../core/interfaces/IProductFilter';
import { ACTIONS_CONSTANTS } from '../../../actions-constants';

export const setMarketplaceProductListFiltersAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_FILTERS,
  (filters: IMarketplaceImportFilter) => filters,
)();

export const resetMarketplaceProductListFiltersAction = createAction(
  ACTIONS_CONSTANTS.RESET_MARKETPLACE_PRODUCT_LIST_FILTERS,
)();

export const clearAllMarketplaceProductListFiltersAction = createAction(
  ACTIONS_CONSTANTS.CLEAR_ALL_MARKETPLACE_PRODUCT_LIST_FILTERS,
)();

export const setMarketplaceProductListQueryFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_QUERY_FILTER,
  (filters: Pick<IMarketplaceImportFilter, 'query'>) => filters,
)();

export const setMarketplaceProductListVendorFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_VENDOR_FILTER,
  (filters: Pick<IMarketplaceImportFilter, 'vendors'>) => filters,
)();

export const setMarketplaceProductListTypeFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_TYPE_FILTER,
  (filters: Pick<IMarketplaceImportFilter, 'types'>) => filters,
)();

export const setMarketplaceProductListPropertyFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_PROPERTY_FILTER,
  (filters: Pick<IMarketplaceImportFilter, 'properties'>) => filters,
)();

export const setMarketplaceProductListSupplierFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_SUPPLIER_FILTER,
  (filters: Pick<IMarketplaceImportFilter, 'fulfilledBy'>) => filters,
)();

export const setMarketplaceProductListConnectionFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_CONNECTION_FILTER,
  (filters: Pick<IMarketplaceImportFilter, 'noConnectedProducts'>) => filters,
)();

export const setMarketplaceProductListCategoryFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_CATEGORY_FILTER,
  (filters: Pick<IMarketplaceImportFilter, 'productCategory'>) => filters,
)();

export const setMarketplaceProductListPageFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_PAGE_FILTER,
  (filters: Pick<Paginated<IMarketplaceImportFilter>, 'page'>) => filters,
)();

export const setMarketplaceProductListLimitFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_LIMIT_FILTER,
  (filters: Pick<Paginated<IMarketplaceImportFilter>, 'limit'>) => filters,
)();

export const setMarketplaceProductListInventoryFilterAction = createAction(
  ACTIONS_CONSTANTS.SET_MARKETPLACE_PRODUCT_LIST_INVENTORY_FILTER,
  (filters: Pick<Paginated<IMarketplaceImportFilter>, 'productInventory'>) => filters,
)();
