import { AxiosResponse } from 'axios';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { PRODUCT_PROPERTIES } from '../../../../core/constants/product.constants';
import { interactiveToastDelay } from '../../../../core/constants/toast.constants';
import { Paginated } from '../../../../core/helpers/generic.helper';
import { IOption } from '../../../../core/interfaces/IOption';
import { IMarketplaceProduct, IProductList } from '../../../../core/interfaces/IProduct';
import {
  IMarketplaceBuildFilter,
  IMarketplaceImportFilter,
} from '../../../../core/interfaces/IProductFilter';
import { IPostProductsResponse } from '../../../api/inventory.api';
import { marketplaceApi } from '../../../api/marketplace.api';
import {
  IConnectedProducts,
  IMarketplaceVariant,
  IVariantList,
} from '../../../interfaces/IProduct';
import { decrementProductsCountAction } from '../status/status.actions';
import {
  addAllMarketplaceProductsToImportListAction,
  addMarketplaceProductsToImportListAction,
  decrementVariantConnectedProductsAction,
  disconnectMarketplaceConnectedProductsAction,
  getMarketplaceConnectedProductsAction,
  getMarketplaceFilterOptionsAction,
  getMarketplaceProductListAction,
  getMarketplaceProductVariantsAction,
  getMarketplaceVariantListAction,
  hideImportListProductsAddedToastAction,
  removeDisconnectedProductsAction,
  resetMarketplaceProductListFiltersAction,
  resetMarketplaceVariantListFiltersAction,
  setMarketplaceConnectedProductsAction,
  setMarketplaceFilterOptionsAction,
  setMarketplaceProductListAction,
  setMarketplaceProductListCategoryFilterAction,
  setMarketplaceProductListConnectionFilterAction,
  setMarketplaceProductListFetchingAction,
  setMarketplaceProductListFiltersAction,
  setMarketplaceProductListLimitFilterAction,
  setMarketplaceProductListPageFilterAction,
  setMarketplaceProductListPropertyFilterAction,
  setMarketplaceProductListQueryFilterAction,
  setMarketplaceProductListSupplierFilterAction,
  setMarketplaceProductListTypeFilterAction,
  setMarketplaceProductListVendorFilterAction,
  setMarketplaceProductVariantsAction,
  setMarketplaceVariantListAction,
  setMarketplaceVariantListConnectionFilterAction,
  setMarketplaceVariantListFetchingAction,
  setMarketplaceVariantListFiltersAction,
  setMarketplaceVariantListLimitFilterAction,
  setMarketplaceVariantListPageFilterAction,
  setMarketplaceVariantListStockFilterAction,
  setMarketplaceVariantListQueryFilterAction,
  setMarketplaceVariantListSupplierFilterAction,
  setMarketplaceVariantListTypeFilterAction,
  setMarketplaceVariantListVendorFilterAction,
  showImportListProductsAddedToastAction,
  clearAllMarketplaceProductListFiltersAction,
  setMarketplaceProductListInventoryFilterAction,
  setMarketplaceVariantListInventoryFilterAction,
} from './marketplace.actions';
import {
  getMarketplaceProductViewFiltersSelector,
  getMarketplaceVariantViewFiltersSelector,
} from './marketplace.selectors';

function* getMarketplaceProductListSaga() {
  try {
    yield put(setMarketplaceProductListFetchingAction(true));
    const filters: Paginated<IMarketplaceImportFilter> = yield select(
      getMarketplaceProductViewFiltersSelector,
    );
    const { data: productList }: AxiosResponse<IProductList<IMarketplaceProduct>> =
      yield marketplaceApi.getProducts(filters);
    yield put(setMarketplaceProductListAction(productList));
    yield put(setMarketplaceProductListFetchingAction(false));
  } catch (e) {
    if ((e as any)?.response?.status === 404) {
      yield put(setMarketplaceProductListAction({ productGroups: [], total: 0 }));
      yield put(setMarketplaceProductListFetchingAction(false));
    } else {
      console.error(e);
    }
  }
}

function* getMarketplaceVariantListSaga() {
  try {
    yield put(setMarketplaceVariantListFetchingAction(true));
    const filters: Paginated<IMarketplaceBuildFilter> = yield select(
      getMarketplaceVariantViewFiltersSelector,
    );
    const { data: variantList }: AxiosResponse<IVariantList> = yield marketplaceApi.getVariants(
      filters,
    );
    yield put(setMarketplaceVariantListAction(variantList));
    yield put(setMarketplaceVariantListFetchingAction(false));
  } catch (e) {
    if ((e as any)?.response?.status === 404) {
      yield put(setMarketplaceVariantListAction({ products: [], total: 0 }));
      yield put(setMarketplaceVariantListFetchingAction(false));
    } else {
      console.error(e);
    }
  }
}

function* getConnectedProductsSaga({
  payload: variantId,
}: ActionType<typeof getMarketplaceConnectedProductsAction>) {
  try {
    yield put(setMarketplaceVariantListFetchingAction(true));
    const { data }: AxiosResponse<{ connectedProducts: IConnectedProducts[] }> =
      yield marketplaceApi.getConnectedProducts(variantId);
    yield put(setMarketplaceConnectedProductsAction(data.connectedProducts || []));
    yield put(setMarketplaceVariantListFetchingAction(false));
  } catch (e) {
    if ((e as any)?.response?.status === 404) {
      yield put(setMarketplaceConnectedProductsAction([]));
      yield put(setMarketplaceVariantListFetchingAction(false));
    } else {
      console.error(e);
    }
  }
}

function* disconnectConnectedProductsSaga({
  payload,
}: ActionType<typeof disconnectMarketplaceConnectedProductsAction>) {
  const { id, variantId } = payload;
  try {
    yield put(setMarketplaceVariantListFetchingAction(true));
    yield marketplaceApi.disconnectVariant(id);
    yield put(decrementVariantConnectedProductsAction(variantId));
    yield put(removeDisconnectedProductsAction(id, variantId));
    yield put(decrementProductsCountAction());
    yield put(setMarketplaceVariantListFetchingAction(false));
  } catch (e) {
    if ((e as any)?.response?.status === 404) {
      yield put(getMarketplaceConnectedProductsAction(variantId));
      yield put(setMarketplaceVariantListFetchingAction(false));
    } else {
      console.error(e);
    }
  }
}

function* getMarketplaceProductVariantsSaga({
  payload: productId,
}: ActionType<typeof getMarketplaceProductVariantsAction>) {
  try {
    yield put(setMarketplaceProductListFetchingAction(true));
    const { data: variants }: AxiosResponse<IMarketplaceVariant[]> =
      yield marketplaceApi.getProductVariants(productId);
    yield put(setMarketplaceProductVariantsAction(productId, variants));
    yield put(setMarketplaceProductListFetchingAction(false));
  } catch (e) {
    console.error(e);
    yield put(setMarketplaceProductListFetchingAction(false));
  }
}

function* getMarketplaceFilterOptionsSaga(
  action: ActionType<typeof getMarketplaceFilterOptionsAction>,
) {
  try {
    const { data: vendors }: AxiosResponse<IOption[]> = yield marketplaceApi.getVendors();
    const { data: fulfilledBy }: AxiosResponse<IOption[]> = yield marketplaceApi.getSuppliers();
    const { data: types }: AxiosResponse<IOption[]> = yield marketplaceApi.getTypes();
    yield put(
      setMarketplaceFilterOptionsAction({
        vendors,
        fulfilledBy,
        types,
        properties: Object.values(PRODUCT_PROPERTIES).map((p) => ({
          value: p,
          label: p,
        })),
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* postProductsToImportListSaga({
  payload,
}: ActionType<typeof addMarketplaceProductsToImportListAction>) {
  try {
    yield put(setMarketplaceProductListFetchingAction(true));
    const { productIds, skipDuplicates } = payload;

    const { data }: AxiosResponse<IPostProductsResponse> =
      yield marketplaceApi.addMarketplaceProductsToImportList(productIds, skipDuplicates);

    yield put(setMarketplaceProductListFetchingAction(false));

    if ('success' in data && data.success && data.added) {
      yield put(showImportListProductsAddedToastAction());
      yield call(interactiveToastDelay);
      yield put(hideImportListProductsAddedToastAction());
    }
  } catch (e) {
    console.error(e);
    yield put(setMarketplaceProductListFetchingAction(false));
  }
}

function* postAllProductsToImportListSaga({
  payload,
}: ActionType<typeof addAllMarketplaceProductsToImportListAction>) {
  try {
    const filters: Paginated<IMarketplaceImportFilter> = yield select(
      getMarketplaceProductViewFiltersSelector,
    );
    yield put(setMarketplaceProductListFetchingAction(true));
    const { data }: AxiosResponse<IPostProductsResponse> =
      yield marketplaceApi.addAllMarketplaceProductsToImportList({
        ...filters,
        skipDuplicates: payload,
      });

    if ('success' in data && data.success) {
      yield put(showImportListProductsAddedToastAction());
      yield call(interactiveToastDelay);
      yield put(hideImportListProductsAddedToastAction());
    }
    yield put(setMarketplaceProductListFetchingAction(false));
  } catch (e) {
    console.error(e);
  }
}

export function* marketplaceSaga() {
  yield all([
    takeLatest(
      [
        getMarketplaceProductListAction,
        setMarketplaceProductListFiltersAction,
        setMarketplaceProductListQueryFilterAction,
        setMarketplaceProductListVendorFilterAction,
        setMarketplaceProductListTypeFilterAction,
        setMarketplaceProductListSupplierFilterAction,
        setMarketplaceProductListPropertyFilterAction,
        setMarketplaceProductListCategoryFilterAction,
        setMarketplaceProductListInventoryFilterAction,
        setMarketplaceProductListLimitFilterAction,
        setMarketplaceProductListPageFilterAction,
        setMarketplaceProductListConnectionFilterAction,
        resetMarketplaceProductListFiltersAction,
        clearAllMarketplaceProductListFiltersAction,
      ],
      getMarketplaceProductListSaga,
    ),
    takeLatest(
      [
        getMarketplaceVariantListAction,
        setMarketplaceVariantListFiltersAction,
        setMarketplaceVariantListQueryFilterAction,
        setMarketplaceVariantListVendorFilterAction,
        setMarketplaceVariantListTypeFilterAction,
        setMarketplaceVariantListStockFilterAction,
        setMarketplaceVariantListSupplierFilterAction,
        setMarketplaceVariantListLimitFilterAction,
        setMarketplaceVariantListPageFilterAction,
        setMarketplaceVariantListConnectionFilterAction,
        setMarketplaceVariantListInventoryFilterAction,
        resetMarketplaceVariantListFiltersAction,
      ],
      getMarketplaceVariantListSaga,
    ),
    takeLatest(getMarketplaceConnectedProductsAction, getConnectedProductsSaga),
    takeEvery(getMarketplaceProductVariantsAction, getMarketplaceProductVariantsSaga),
    takeLatest(getMarketplaceFilterOptionsAction, getMarketplaceFilterOptionsSaga),
    takeLatest(disconnectMarketplaceConnectedProductsAction, disconnectConnectedProductsSaga),
    takeLatest(addMarketplaceProductsToImportListAction, postProductsToImportListSaga),
    takeLatest(addAllMarketplaceProductsToImportListAction, postAllProductsToImportListSaga),
  ]);
}
