import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

export const getOrdersReducerSelector = createSelector(
  [(state: RootState) => state.orders],
  (ordersReducer) => ordersReducer,
);

export const getOrdersSummarySelector = createSelector(
  [(state: RootState) => state.orders.ordersSummary],
  (ordersSummary) => ordersSummary,
);

export const getUnpaidOrdersSummarySelector = createSelector(
  [(state: RootState) => state.orders.unpaidOrdersSummary],
  (unpaidOrdersSummary) => unpaidOrdersSummary,
);

export const getOrdersListSelector = createSelector(
  [(state: RootState) => state.orders.orderList.orders],
  (orders) => orders,
);

export const getUnpaidOrdersListSelector = createSelector(
  [(state: RootState) => state.orders.unpaidOrderList.orders],
  (orders) => orders,
);

export const getOrdersFiltersSelector = createSelector(
  [(state: RootState) => state.orders.filters],
  (filters) => filters,
);

export const getOrdersTotalsDetailsSelector = createSelector(
  [(state: RootState) => state.orders.totalDetails],
  (totalDetails) => totalDetails,
);

export const getRetailersSelector = createSelector(
  [(state: RootState) => state.orders.retailers],
  (retailers) => retailers,
);

export const getOrdersListFetchingSelector = createSelector(
  [(state: RootState) => state.orders.fetchingOrdersList],
  (status) => status,
);

export const getUnpaidOrdersListFetchingSelector = createSelector(
  [(state: RootState) => state.orders.fetchingUnpaidOrdersList],
  (status) => status,
);

export const getOrdersSummaryFetchingSelector = createSelector(
  [(state: RootState) => state.orders.fetchingOrdersSummary],
  (status) => status,
);

export const getOrderCancellationFetchingSelector = createSelector(
  [(state: RootState) => state.orders.fetchingCancellation],
  (status) => status,
);

export const getOrdersCountSelector = createSelector(
  [(state: RootState) => state.orders.orderList.total],
  (count) => count,
);

export const getUnpaidOrdersCountSelector = createSelector(
  [(state: RootState) => state.orders.unpaidOrderList.total],
  (count) => count,
);

export const getOrderDetailsSelector = createSelector(
  [(state: RootState, orderId: string) => state.orders.orderList.details[orderId]],
  (details) => details || null,
);

export const getPayoutListSelector = createSelector(
  [(state: RootState) => state.orders.payoutList],
  (payoutList) => payoutList,
);

export const getPayoutFetchingSelector = createSelector(
  [(state: RootState) => state.orders.fetchingPayoutList],
  (fetchingPayoutList) => fetchingPayoutList,
);

export const acceptingCancellationSelector = createSelector(
  [(state: RootState) => state.orders.acceptingCancellation],
  (acceptingCancellation) => acceptingCancellation,
);

export const decliningCancellationSelector = createSelector(
  [(state: RootState) => state.orders.decliningCancellation],
  (decliningCancellation) => decliningCancellation,
);
