export enum ACTIONS_CONSTANTS {
  // dashboard
  GET_DASHBOARD_ORDERS_SUMMARY = 'GET_DASHBOARD_ORDERS_SUMMARY',
  SET_DASHBOARD_ORDERS_SUMMARY = 'SET_DASHBOARD_ORDERS_SUMMARY',
  GET_DASHBOARD_PRODUCTS_SUMMARY = 'GET_DASHBOARD_PRODUCTS_SUMMARY',
  SET_DASHBOARD_PRODUCTS_SUMMARY = 'SET_DASHBOARD_PRODUCTS_SUMMARY',
  GET_DASHBOARD_SALES_SUMMARY = 'GET_DASHBOARD_SALES_SUMMARY',
  SET_DASHBOARD_SALES_SUMMARY = 'SET_DASHBOARD_SALES_SUMMARY',

  // marketplace layout
  GET_ALL_SUPPLIERS = 'GET_ALL_SUPPLIERS',
  SET_ALL_SUPPLIERS = 'SET_ALL_SUPPLIERS',

  GET_MARKETPLACE_PRODUCT_LIST = 'GET_MARKETPLACE_PRODUCT_LIST',
  SET_MARKETPLACE_PRODUCT_LIST = 'SET_MARKETPLACE_PRODUCT_LIST',

  SET_MARKETPLACE_PRODUCT_LIST_FETCHING = 'SET_MARKETPLACE_PRODUCT_LIST_FETCHING',

  GET_MARKETPLACE_PRODUCT_VARIANTS = 'GET_MARKETPLACE_PRODUCT_VARIANTS',
  SET_MARKETPLACE_PRODUCT_VARIANTS = 'SET_MARKETPLACE_PRODUCT_VARIANTS',

  GET_MARKETPLACE_CONNECTED_PRODUCTS = 'GET_MARKETPLACE_CONNECTED_PRODUCTS',
  SET_MARKETPLACE_CONNECTED_PRODUCTS = 'SET_MARKETPLACE_CONNECTED_PRODUCTS',

  DISCONNECT_MARKETPLACE_CONNECTED_PRODUCTS = 'DISCONNECT_MARKETPLACE_CONNECTED_PRODUCTS',
  DECREMENT_VARIANT_CONNECTED_PRODUCTS = 'DECREMENT_VARIANT_CONNECTED_PRODUCTS',
  REMOVE_DISCONNECTED_PRODUCTS = 'REMOVE_DISCONNECTED_PRODUCTS',

  GET_MARKETPLACE_FILTER_OPTIONS = 'GET_MARKETPLACE_FILTER_OPTIONS',
  SET_MARKETPLACE_FILTER_OPTIONS = 'SET_MARKETPLACE_FILTER_OPTIONS',

  SHOW_IMPORT_LIST_PRODUCTS_ADDED_TOAST = 'SHOW_IMPORT_LIST_PRODUCTS_ADDED_TOAST',
  HIDE_IMPORT_LIST_PRODUCTS_ADDED_TOAST = 'HIDE_IMPORT_LIST_PRODUCTS_ADDED_TOAST',
  SET_IMPORT_LIST__ADDED = 'SET_IMPORT_LIST__ADDED',

  SHOW_PRODUCT_LIMIT_EXCEEDED_ERROR_BANNER = 'SHOW_PRODUCT_LIMIT_EXCEEDED_ERROR_BANNER',
  HIDE_PRODUCT_LIMIT_EXCEEDED_ERROR_BANNER = 'HIDE_PRODUCT_LIMIT_EXCEEDED_ERROR_BANNER',

  SHOW_PRODUCT_LIMIT_EXCEEDED_MODAL = 'SHOW_PRODUCT_LIMIT_EXCEEDED_MODAL',
  HIDE_PRODUCT_LIMIT_EXCEEDED_MODAL = 'HIDE_PRODUCT_LIMIT_EXCEEDED_MODAL',

  SET_MARKETPLACE_PRODUCT_LIST_FILTERS = 'SET_MARKETPLACE_PRODUCT_LIST_FILTERS',
  RESET_MARKETPLACE_PRODUCT_LIST_FILTERS = 'RESET_MARKETPLACE_PRODUCT_LIST_FILTERS',
  CLEAR_ALL_MARKETPLACE_PRODUCT_LIST_FILTERS = 'CLEAR_ALL_MARKETPLACE_PRODUCT_LIST_FILTERS',
  SET_MARKETPLACE_PRODUCT_LIST_QUERY_FILTER = 'SET_MARKETPLACE_PRODUCT_LIST_QUERY_FILTER',
  SET_MARKETPLACE_PRODUCT_LIST_VENDOR_FILTER = 'SET_MARKETPLACE_PRODUCT_LIST_VENDOR_FILTER',
  SET_MARKETPLACE_PRODUCT_LIST_TYPE_FILTER = 'SET_MARKETPLACE_PRODUCT_LIST_TYPE_FILTER',
  SET_MARKETPLACE_PRODUCT_LIST_PROPERTY_FILTER = 'SET_MARKETPLACE_PRODUCT_LIST_PROPERTY_FILTER',
  SET_MARKETPLACE_PRODUCT_LIST_SUPPLIER_FILTER = 'SET_MARKETPLACE_PRODUCT_LIST_SUPPLIER_FILTER',
  SET_MARKETPLACE_PRODUCT_LIST_STOCK_FILTER = 'SET_MARKETPLACE_PRODUCT_LIST_STOCK_FILTER',
  SET_MARKETPLACE_PRODUCT_LIST_CONNECTION_FILTER = 'SET_MARKETPLACE_PRODUCT_LIST_CONNECTION_FILTER',
  SET_MARKETPLACE_PRODUCT_LIST_CATEGORY_FILTER = 'SET_MARKETPLACE_PRODUCT_LIST_CATEGORY_FILTER',
  SET_MARKETPLACE_PRODUCT_LIST_PAGE_FILTER = 'SET_MARKETPLACE_PRODUCT_LIST_PAGE_FILTER',
  SET_MARKETPLACE_PRODUCT_LIST_LIMIT_FILTER = 'SET_MARKETPLACE_PRODUCT_LIST_LIMIT_FILTER',
  SET_MARKETPLACE_PRODUCT_LIST_INVENTORY_FILTER = 'SET_MARKETPLACE_PRODUCT_LIST_INVENTORY_FILTER',

  GET_MARKETPLACE_VARIANT_LIST = 'GET_MARKETPLACE_VARIANT_LIST',
  SET_MARKETPLACE_VARIANT_LIST = 'SET_MARKETPLACE_VARIANT_LIST',
  SET_MARKETPLACE_VARIANT_LIST_FETCHING = 'SET_MARKETPLACE_VARIANT_LIST_FETCHING',

  SET_MARKETPLACE_VARIANT_LIST_FILTERS = 'SET_MARKETPLACE_VARIANT_LIST_FILTERS',
  RESET_MARKETPLACE_VARIANT_LIST_FILTERS = 'RESET_MARKETPLACE_VARIANT_LIST_FILTERS',
  SET_MARKETPLACE_VARIANT_LIST_QUERY_FILTER = 'SET_MARKETPLACE_VARIANT_LIST_QUERY_FILTER',
  SET_MARKETPLACE_VARIANT_LIST_VENDOR_FILTER = 'SET_MARKETPLACE_VARIANT_LIST_VENDOR_FILTER',
  SET_MARKETPLACE_VARIANT_LIST_TYPE_FILTER = 'SET_MARKETPLACE_VARIANT_LIST_TYPE_FILTER',
  SET_MARKETPLACE_VARIANT_LIST_PROPERTY_FILTER = 'SET_MARKETPLACE_VARIANT_LIST_PROPERTY_FILTER',
  SET_MARKETPLACE_VARIANT_LIST_SUPPLIER_FILTER = 'SET_MARKETPLACE_VARIANT_LIST_SUPPLIER_FILTER',
  SET_MARKETPLACE_VARIANT_LIST_STOCK_FILTER = 'SET_MARKETPLACE_VARIANT_LIST_STOCK_FILTER',
  SET_MARKETPLACE_VARIANT_LIST_CONNECTION_FILTER = 'SET_MARKETPLACE_VARIANT_LIST_CONNECTION_FILTER',
  SET_MARKETPLACE_VARIANT_LIST_PAGE_FILTER = 'SET_MARKETPLACE_VARIANT_LIST_PAGE_FILTER',
  SET_MARKETPLACE_VARIANT_LIST_LIMIT_FILTER = 'SET_MARKETPLACE_VARIANT_LIST_LIMIT_FILTER',
  SET_MARKETPLACE_VARIANT_LIST_INVENTORY_FILTER = 'SET_MARKETPLACE_VARIANT_LIST_INVENTORY_FILTER',

  SET_MARKETPLACE_CONNECTION_LIST_FILTERS = 'SET_MARKETPLACE_CONNECTION_LIST_FILTERS',
  RESET_MARKETPLACE_CONNECTION_LIST_FILTERS = 'RESET_MARKETPLACE_CONNECTION_LIST_FILTERS',
  SET_MARKETPLACE_CONNECTION_LIST_QUERY_FILTER = 'SET_MARKETPLACE_CONNECTION_LIST_QUERY_FILTER',
  SET_MARKETPLACE_CONNECTION_LIST_VENDOR_FILTER = 'SET_MARKETPLACE_CONNECTION_LIST_VENDOR_FILTER',
  SET_MARKETPLACE_CONNECTION_LIST_TYPE_FILTER = 'SET_MARKETPLACE_CONNECTION_LIST_TYPE_FILTER',
  SET_MARKETPLACE_CONNECTION_LIST_SUPPLIER_FILTER = 'SET_MARKETPLACE_CONNECTION_LIST_SUPPLIER_FILTER',
  SET_MARKETPLACE_CONNECTION_LIST_CONNECTION_FILTER = 'SET_MARKETPLACE_CONNECTION_LIST_CONNECTION_FILTER',
  SET_MARKETPLACE_CONNECTION_LIST_PAGE_FILTER = 'SET_MARKETPLACE_CONNECTION_LIST_PAGE_FILTER',
  SET_MARKETPLACE_CONNECTION_LIST_LIMIT_FILTER = 'SET_MARKETPLACE_CONNECTION_LIST_LIMIT_FILTER',

  POST_PRODUCT_FROM_MARKETPLACE_TO_INVENTORY = 'POST_PRODUCT_FROM_MARKETPLACE_TO_INVENTORY',
  DISCONNECT_PRODUCTS_FROM_INVENTORY = 'DISCONNECT_PRODUCTS_FROM_INVENTORY',
  SET_MARKETPLACE_FETCHING = 'SET_MARKETPLACE_FETCHING',

  POST_PRODUCTS_TO_IMPORT_LIST = 'POST_PRODUCTS_TO_IMPORT_LIST',
  POST_ALL_PRODUCTS_TO_IMPORT_LIST = 'POST_ALL_PRODUCTS_TO_IMPORT_LIST',

  // inventory layout
  GET_INVENTORY_PRODUCT_LIST = 'GET_INVENTORY_PRODUCT_LIST',
  SET_INVENTORY_PRODUCT_LIST = 'SET_INVENTORY_PRODUCT_LIST',
  SET_REMOVED_INVENTORY_PRODUCT_LIST = 'SET_REMOVED_INVENTORY_PRODUCT_LIST',

  GET_INVENTORY_FILTER_OPTIONS = 'GET_INVENTORY_FILTER_OPTIONS',
  SET_INVENTORY_FILTER_OPTIONS = 'SET_INVENTORY_FILTER_OPTIONS',

  EXPORT_CSV = 'EXPORT_CSV',
  EXPORT_CSV_ALL = 'EXPORT_CSV_ALL',

  SET_INVENTORY_FETCHING = 'SET_INVENTORY_FETCHING',

  DELETE_PRODUCTS_FROM_INVENTORY = 'DELETE_PRODUCTS_FROM_INVENTORY',

  GET_INVENTORY_PRODUCT_VARIANTS = 'GET_INVENTORY_PRODUCT_VARIANTS',
  SET_INVENTORY_PRODUCT_VARIANTS = 'SET_INVENTORY_PRODUCT_VARIANTS',

  SET_INVENTORY_PRODUCT_LIST_FILTERS = 'SET_INVENTORY_PRODUCT_LIST_FILTERS',
  RESET_INVENTORY_PRODUCT_LIST_FILTERS = 'RESET_INVENTORY_PRODUCT_LIST_FILTERS',
  SET_INVENTORY_PRODUCT_LIST_QUERY_FILTER = 'SET_INVENTORY_PRODUCT_LIST_QUERY_FILTER',
  SET_INVENTORY_PRODUCT_LIST_VENDOR_FILTER = 'SET_INVENTORY_PRODUCT_LIST_VENDOR_FILTER',
  SET_INVENTORY_PRODUCT_LIST_TYPE_FILTER = 'SET_INVENTORY_PRODUCT_LIST_TYPE_FILTER',
  SET_INVENTORY_PRODUCT_LIST_PROPERTIES_FILTER = 'SET_INVENTORY_PRODUCT_LIST_PROPERTIES_FILTER',
  SET_INVENTORY_PRODUCT_LIST_SUPPLIER_FILTER = 'SET_INVENTORY_PRODUCT_LIST_SUPPLIER_FILTER',
  SET_INVENTORY_PRODUCT_LIST_STOCK_FILTER = 'SET_INVENTORY_PRODUCT_LIST_STOCK_FILTER',
  SET_INVENTORY_PRODUCT_LIST_CONNECTION_FILTER = 'SET_INVENTORY_PRODUCT_LIST_CONNECTION_FILTER',
  SET_INVENTORY_PRODUCT_LIST_PAGE_FILTER = 'SET_INVENTORY_PRODUCT_LIST_PAGE_FILTER',
  SET_INVENTORY_PRODUCT_LIST_LIMIT_FILTER = 'SET_INVENTORY_PRODUCT_LIST_LIMIT_FILTER',
  SET_INVENTORY_PRODUCT_LIST_FILTER = 'SET_INVENTORY_PRODUCT_LIST_FILTER',

  // import list
  GET_IMPORT_LIST_PRODUCTS = 'GET_IMPORT_LIST_PRODUCTS',
  SET_IMPORT_LIST_PRODUCTS = 'SET_IMPORT_LIST_PRODUCTS',
  SET_IMPORT_LIST_PRODUCT = 'SET_IMPORT_LIST_PRODUCT',
  POST_PRODUCTS_FROM_IMPORT_LIST_TO_INVENTORY = 'POST_PRODUCTS_FROM_IMPORT_LIST_TO_INVENTORY',
  POST_ALL_PRODUCTS_FROM_IMPORT_LIST_TO_INVENTORY = 'POST_ALL_PRODUCTS_FROM_IMPORT_LIST_TO_INVENTORY',
  DELETE_PRODUCT_FROM_IMPORT_LIST = 'DELETE_PRODUCT_FROM_IMPORT_LIST',
  SHOW_PRODUCT_REMOVED_TOAST = 'SHOW_PRODUCT_REMOVED_TOAST',
  HIDE_PRODUCT_REMOVED_TOAST = 'HIDE_PRODUCT_REMOVED_TOAST',
  SHOW_INVENTORY_PRODUCTS_ADDED_TOAST = 'SHOW_INVENTORY_PRODUCTS_ADDED_TOAST',
  HIDE_INVENTORY_PRODUCTS_ADDED_TOAST = 'HIDE_INVENTORY_PRODUCTS_ADDED_TOAST',

  // settings
  GET_SETTINGS = 'GET_SETTINGS',
  GET_PRODUCT_SETTINGS = 'GET_PRODUCT_SETTINGS',
  SET_SETTINGS = 'SET_SETTINGS',
  SET_SETTINGS_FROM_API = 'SET_SETTINGS_FROM_API',
  SET_PRODUCT_SETTINGS_FROM_API = 'SET_PRODUCT_SETTINGS_FROM_API',
  POST_SETTINGS = 'POST_SETTINGS',
  SHOW_SUCCESS_SETTINGS_UPDATE_TOAST = 'SHOW_SUCCESS_SETTINGS_UPDATE_TOAST',
  HIDE_SUCCESS_SETTINGS_UPDATE_TOAST = 'HIDE_SUCCESS_SETTINGS_UPDATE_TOAST',
  CALC_PRICE_CHANGES = 'CALC_PRICE_CHANGES',
  SET_PRICE_CHANGES = 'SET_PRICE_CHANGES',
  POST_All_PRODUCTS_TO_INVENTORY = 'POST_All_PRODUCTS_TO_INVENTORY',
  DELETE_All_PRODUCTS_FROM_INVENTORY = 'DELETE_All_PRODUCTS_FROM_INVENTORY',

  // sync
  LINK_SYNC_MATCHES = 'LINK_SYNC_MATCHES',
  LINK_INVENTORY_MATCHES = 'LINK_INVENTORY_MATCHES',
  LINK_ALL_INVENTORY_MATCHES = 'LINK_ALL_INVENTORY_MATCHES',
  SHOW_PRODUCT_LINK_TOAST = 'SHOW_PRODUCT_LINK_TOAST',
  HIDE_PRODUCT_LINK_TOAST = 'HIDE_PRODUCT_LINK_TOAST',
  SHOW_VARIANT_LINK_TOAST = 'SHOW_VARIANT_LINK_TOAST',
  HIDE_VARIANT_LINK_TOAST = 'HIDE_VARIANT_LINK_TOAST',
  GET_DISCONNECTED_PRODUCTS = 'GET_DISCONNECTED_PRODUCTS',
  SET_DISCONNECTED_PRODUCTS = 'SET_DISCONNECTED_PRODUCTS',
  MARK_DISCONNECTED_PRODUCTS_AS_DRAFT = 'MARK_DISCONNECTED_PRODUCTS_AS_DRAFT',
  LINK_ALL_SYNC_MATCHES = 'LINK_ALL_SYNC_MATCHES',
  SET_VENDORS_FOR_MATCHES = 'SET_VENDORS_FOR_MATCHES',
  GET_VENDORS_FOR_MATCHES = 'GET_VENDORS_FOR_MATCHES',
  START_PRODUCT_SYNC = 'START_PRODUCT_SYNC',
  RESOLVE_SYNC_CONFLICT = 'RESOLVE_SYNC_CONFLICT',
  SET_SYNC_MATCHES = 'SET_SYNC_MATCHES',
  SET_SYNC_CONFLICTS = 'SET_SYNC_CONFLICTS',
  SET_SYNC_STATUS = 'SET_SYNC_STATUS',
  GET_SYNC_MATCHES = 'GET_SYNC_MATCHES',
  GET_SYNC_CONFLICTS = 'GET_SYNC_CONFLICTS',
  GET_SYNC_STATUS = 'GET_SYNC_STATUS',
  SHOW_SYNC_PRODUCT_LIMIT_EXCEEDED_BANNER = 'SHOW_SYNC_PRODUCT_LIMIT_EXCEEDED_BANNER',
  HIDE_SYNC_PRODUCT_LIMIT_EXCEEDED_BANNER = 'HIDE_SYNC_PRODUCT_LIMIT_EXCEEDED_BANNER',

  // account and plans
  GET_PLANS = 'GET_PLANS',
  SET_PLANS = 'SET_PLANS',
  SHOW_SUCCESS_CURRENT_PLAN_CHANGE_TOAST = 'SHOW_SUCCESS_CURRENT_PLAN_CHANGE_TOAST',
  HIDE_SUCCESS_CURRENT_PLAN_CHANGE_TOAST = 'HIDE_SUCCESS_CURRENT_PLAN_CHANGE_TOAST',
  SHOW_PRODUCTS_LIMIT_EXCEEDED_ERROR_PLAN_CHANGE_BANNER = 'SHOW_PRODUCTS_LIMIT_EXCEEDED_ERROR_PLAN_CHANGE_BANNER',
  HIDE_PRODUCTS_LIMIT_EXCEEDED_ERROR_PLAN_CHANGE_BANNER = 'HIDE_PRODUCTS_LIMIT_EXCEEDED_ERROR_PLAN_CHANGE_BANNER',
  UPDATE_STATUS_ON_PLAN_CHANGE = 'UPDATE_PLAN_LIMIT_STATUS_ON_PLAN_CHANGE',
  MARK_ACCOUNT_INFO_AS_FILLED = 'MARK_ACCOUNT_INFO_AS_FILLED',
  CHANGE_CURRENT_PLAN = 'CHANGE_CURRENT_PLAN',
  UPDATE_PRODUCTS_LIMIT_STATUS = 'UPDATE_PRODUCTS_LIMIT_STATUS',
  SET_STATUS = 'SET_PLAN_LIMITS_STATUS',
  GET_STATUS = 'GET_PLAN_LIMITS_STATUS',
  UPDATE_STATUS_ON_ACCOUNT_LOADED = 'UPDATE_STATUS_ON_ACCOUNT_LOADED',
  UPDATE_STATUS_ON_PREFERENCES_LOADED = 'UPDATE_STATUS_ON_PREFERENCES_LOADED',
  DISABLE_PROMOTION_BANNER = 'DISABLE_PROMOTION_BANNER',

  // orders layout
  SET_ORDERS_FETCHING = 'SET_ORDERS_FETCHING',
  SET_ORDERS_DETAILS_FETCHING = 'SET_ORDERS_DETAILS_FETCHING',
  GET_ORDERS = 'GET_ORDERS',
  SET_ORDERS = 'SET_ORDERS',
  GET_ORDER_DETAILS = 'GET_ORDER_DETAILS',
  SET_ORDER_DETAILS = 'SET_ORDER_DETAILS',
  GET_ORDERS_SUMMARY = 'GET_ORDERS_SUMMARY',
  SET_ORDERS_SUMMARY = 'SET_ORDERS_SUMMARY',
  REQUEST_CANCELLATION = 'REQUEST_CANCELLATION',
  REVOKE_CANCELLATION = 'REVOKE_CANCELLATION',
  CHARGE_FOR_ORDER = 'CHARGE_FOR_ORDER',
  PROCESS_ORDER = 'PROCESS_ORDER',
  SET_PROCESS = 'SET_PROCESS',
  EDIT_ADDRESS = 'EDIT_ADDRESS',
  SET_ADDRESS = 'SET_ADDRESS',
  SHOW_PAYMENT_FAILED_TOAST = 'SHOW_PAYMENT_FAILED_TOAST',
  HIDE_PAYMENT_FAILED_TOAST = 'HIDE_PAYMENT_FAILED_TOAST',
  SHOW_ADDRESS_VALIDATION_FAILED_TOAST = 'SHOW_ADDRESS_VALIDATION_FAILED_TOAST',
  HIDE_ADDRESS_VALIDATION_FAILED_TOAST = 'HIDE_ADDRESS_VALIDATION_FAILED_TOAST',
  SHOW_ADDRESS_VALIDATION_SUCCESS_TOAST = 'SHOW_ADDRESS_VALIDATION_SUCCESS_TOAST',
  HIDE_ADDRESS_VALIDATION_SUCCESS_TOAST = 'HIDE_ADDRESS_VALIDATION_SUCCESS_TOAST',
  SET_ADDRESS_VALIDATION = 'SET_ADDRESS_VALIDATION',
  CANCEL_ORDER = 'CANCEL_ORDER',
  ACCEPT_CROWDSHIP_CANCELLATION = 'ACCEPT_CROWDSHIP_CANCELLATION',
  HIDE_UPDATING_CANCELLATION_STATUS_TOAST = 'HIDE_UPDATING_CANCELLATION_STATUS_TOAST',
  REMOVE_CARRIER_SERVICE_SHIPPING_OPTION = 'REMOVE_CARRIER_SERVICE_SHIPPING_OPTION',
  ADD_CARRIER_SERVICE_SHIPPING_OPTION = 'ADD_CARRIER_SERVICE_SHIPPING_OPTION',
  GET_CARRIER_SERVICES = 'GET_CARRIER_SERVICES',
  SET_CARRIER_SERVICES = 'SET_CARRIER_SERVICES',
  START_INVENTORY_SYNC = 'START_INVENTORY_SYNC',
  SET_SYNC_INVENTORY_MATCHES = 'SET_SYNC_INVENTORY_MATCHES',
  SET_SYNC_INVENTORY_STATUS = 'SET_SYNC_INVENTORY_STATUS',
  GET_SYNC_INVENTORY_MATCHES = 'GET_SYNC_INVENTORY_MATCHES',
  GET_SYNC_INVENTORY_STATUS = 'GET_SYNC_INVENTORY_STATUS',
  SET_FETCHING_MATCHES = 'SET_FETCHING_MATCHES',
  DISCONNECT_All_PRODUCTS_FROM_INVENTORY = 'DISCONNECT_All_PRODUCTS_FROM_INVENTORY',
  CHECK_PLAN_CHANGE_CONFIRMATION = 'CHECK_PLAN_CHANGE_CONFIRMATION',
  SET_MARKETPLACE_PRODUCT_VARIANT_DRAFT_PRICE = 'SET_MARKETPLACE_PRODUCT_VARIANT_DRAFT_PRICE',
  DECREMENT_PRODUCTS_COUNT = 'DECREMENT_PRODUCTS_COUNT',
  SHOW_IMPORT_LIST_FETCHING_ERROR_TOAST = 'SHOW_IMPORT_LIST_FETCHING_ERROR_TOAST',
  HIDE_IMPORT_LIST_FETCHING_ERROR_TOAST = 'HIDE_IMPORT_LIST_FETCHING_ERROR_TOAST',
}
