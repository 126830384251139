import { useState } from 'react';
import { AppButton } from '../../../../../../core/components/button/Button';
import { AppCollapsible } from '../../../../../../core/components/collapsible/Collapsible';
import { AppControlledCheckbox } from '../../../../../../core/components/forms/controlled-checkbox/controlled-checkbox';
import { AppControlledSelect } from '../../../../../../core/components/forms/controlled-select/controlled-select';
import { AppControlledTextField } from '../../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppControlledRadioGroup } from '../../../../../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import { AppCard } from '../../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../../core/components/structure/layout/layout-annotated-section';
import { AppStack } from '../../../../../../core/components/structure/stack/Stack';
import { AppTextContainer } from '../../../../../../core/components/text-container/text-container';
import { AppTextStyle } from '../../../../../../core/components/text/text-style/TextStyle';
import { validation } from '../../../../../../core/helpers/validations.helper';
import {
  DROP_SHIP_COST_MAPPING,
  MSRP_MAPPING,
  adjustmentTypeOptions,
} from '../../../../../constants/preferences.constants';
import { AdjustField } from '../adjust-field';
import { IPreferencesSectionProps } from '../IPreferencesSectionProps';
import './products-section.scss';

export const ProductsSection = ({ values, currency }: IPreferencesSectionProps) => {
  const [showPriceOptions, setShowPriceOptions] = useState(
    !!values.product.dropShipCost.adjustment?.amount || false,
  );
  const [showMSRPOptions, setShowMSRPOptions] = useState(
    !!values.product.MSRP.adjustment?.amount || false,
  );

  return (
    <AppLayoutAnnotatedSection title="Products">
      <AppCard sectioned title="Marketplace product price recalculation">
        <AppTextContainer>When I change my product price settings</AppTextContainer>
        <AppControlledRadioGroup
          name="product.updatePrices"
          optionsDesc={[
            {
              value: 'false',
              label: (
                <AppTextStyle variation="strong">
                  Do not recalculate prices of Marketplace products (default)
                </AppTextStyle>
              ),
            },
            {
              value: 'true',
              label: (
                <AppTextStyle variation="strong">
                  Recalculate prices of products already added to Marketplace
                </AppTextStyle>
              ),
              helpText: (
                <>
                  <AppTextStyle>
                    If enabled, dropship prices will automatically recalculate when your source
                    product is updated.
                  </AppTextStyle>
                  <p className="warning-message">
                    <em className="warning-color">
                      Warning: All product updates will trigger a price update, including including
                      stock changes and non-price related changes.
                    </em>
                  </p>
                </>
              ),
            },
          ]}
        />
      </AppCard>
      <AppCard sectioned title="Define your Drop Ship Cost">
        <AppTextContainer>
          Precalculate drop ship costs by mapping your Shopify fields. You can modify individual
          prices before products go live on Crowdship, and you can update costs/prices at any time.
        </AppTextContainer>
        <AppControlledRadioGroup
          optionsDesc={[
            {
              value: DROP_SHIP_COST_MAPPING.MANUAL,
              label: <AppTextStyle variation="strong">Defined manually</AppTextStyle>,
              helpText:
                'Crowdship will not suggest any drop ship costs. You will need to enter a drop ship cost for each SKU manually.',
            },
            {
              value: DROP_SHIP_COST_MAPPING.SALE_PRICE,
              label: <AppTextStyle variation="strong">Shopify price</AppTextStyle>,
              disabled: values.product.MSRP.mapping === MSRP_MAPPING.SALE_PRICE,
              helpText:
                'Use your Shopify Variant Price as your drop ship price. Ex. Shopify price = $5.00. Retailers will pay $5.00 for this SKU.',
            },
            {
              value: DROP_SHIP_COST_MAPPING.COMPARE_AT_PRICE,
              label: <AppTextStyle variation="strong">Shopify compare at price</AppTextStyle>,
              disabled: values.product.MSRP.mapping === MSRP_MAPPING.COMPARE_AT_PRICE,
              helpText:
                'Use your Shopify Variant Compare At Price as your drop ship price. Ex. Shopify Compare At Price = $10.00. Retailers will pay $10.00 for this SKU.',
            },
            {
              value: DROP_SHIP_COST_MAPPING.COST,
              label: <AppTextStyle variation="strong">Shopify product cost</AppTextStyle>,
              helpText:
                'Use your Shopify Variant Cost as your drop ship price. Ex. Shopify Cost = $2.50. Retailers will pay $2.50 for this SKU.',
            },
            {
              value: DROP_SHIP_COST_MAPPING.MARKDOWN_FROM_MSRP,
              label: (
                <AppTextStyle variation="strong">
                  Calculate from SRP (using Advanced Options Below)
                </AppTextStyle>
              ),
              disabled: values.product.MSRP.mapping === MSRP_MAPPING.MARKUP_FROM_DROP_SHIP_COST,
            },
          ]}
          name="product.dropShipCost.mapping"
        />
        <br />
        {values.product.dropShipCost.mapping !== DROP_SHIP_COST_MAPPING.MANUAL && (
          <AppStack vertical>
            <AppButton onClick={() => setShowPriceOptions((oldValue) => !oldValue)}>
              {showPriceOptions ? 'Hide' : 'Show'} advanced options
            </AppButton>
            <AppCollapsible id="advancedPriceOptions" open={showPriceOptions}>
              <div className="adjustment">
                <AdjustField
                  mapping={values.product.dropShipCost.mapping}
                  type={values.product.dropShipCost.adjustment?.type}
                  amount={values.product.dropShipCost.adjustment?.amount || '0'}
                  currency={currency}
                />
                {(values.product.dropShipCost.mapping === DROP_SHIP_COST_MAPPING.COMPARE_AT_PRICE ||
                  values.product.dropShipCost.mapping === DROP_SHIP_COST_MAPPING.COST) && (
                  <div className="use-price">
                    <AppControlledCheckbox
                      label={
                        <AppTextStyle variation="strong">
                          Use Product's Shopify "Price" when{' '}
                          {values.product.dropShipCost.mapping ===
                          DROP_SHIP_COST_MAPPING.COMPARE_AT_PRICE
                            ? '"Compare at price"'
                            : '"Cost per item"'}{' '}
                          is not available
                        </AppTextStyle>
                      }
                      name="product.dropShipCost.useSalePrice"
                    />
                    {values.product.dropShipCost.useSalePrice && (
                      <div className="use-price-adjustment">
                        <AppControlledTextField
                          label="Adjust by (+/-)"
                          helpText="The price will be adjusted by this amount"
                          name="product.dropShipCost.salePriceAdjustment.amount"
                          type="number"
                          placeholder="0"
                          min={0}
                          validate={validation.isOptionalNumber}
                          connectedRight={
                            <AppControlledSelect
                              name="product.dropShipCost.salePriceAdjustment.type"
                              options={adjustmentTypeOptions(currency)}
                              label="Adjustment type"
                              labelHidden
                            />
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </AppCollapsible>
          </AppStack>
        )}
      </AppCard>
      <AppCard sectioned title="SRP is">
        <AppControlledRadioGroup
          optionsDesc={[
            {
              value: MSRP_MAPPING.MANUAL,
              label: 'Defined manually',
            },
            {
              value: MSRP_MAPPING.COMPARE_AT_PRICE,
              label: 'Shopify compare at price',
              disabled:
                values.product.dropShipCost.mapping === DROP_SHIP_COST_MAPPING.COMPARE_AT_PRICE,
            },
            {
              value: MSRP_MAPPING.SALE_PRICE,
              label: 'Shopify price',
              disabled: values.product.dropShipCost.mapping === DROP_SHIP_COST_MAPPING.SALE_PRICE,
            },
            {
              value: MSRP_MAPPING.MARKUP_FROM_DROP_SHIP_COST,
              label: 'Calculate from drop ship cost (using default markup)',
              disabled:
                values.product.dropShipCost.mapping === DROP_SHIP_COST_MAPPING.MARKDOWN_FROM_MSRP,
            },
          ]}
          name="product.MSRP.mapping"
        />
        <br />
        {values.product.MSRP.mapping !== MSRP_MAPPING.MANUAL && (
          <AppStack vertical>
            <AppButton onClick={() => setShowMSRPOptions((oldValue) => !oldValue)}>
              {showMSRPOptions ? 'Hide' : 'Show'} advanced SRP options
            </AppButton>
            <AppCollapsible id="advancedMSRPOptions" open={showMSRPOptions}>
              <div className="adjustment">
                <AppControlledTextField
                  label="Adjust by (+/-)"
                  helpText="The price will be adjusted by this amount"
                  name="product.MSRP.adjustment.amount"
                  type="number"
                  placeholder="0"
                  min={0}
                  validate={validation.isOptionalNumber}
                  connectedRight={
                    <AppControlledSelect
                      name="product.MSRP.adjustment.type"
                      options={adjustmentTypeOptions(currency)}
                      label="Adjustment type"
                      labelHidden
                    />
                  }
                />
                {values.product.MSRP.mapping === MSRP_MAPPING.COMPARE_AT_PRICE && (
                  <div className="use-price">
                    <AppControlledCheckbox
                      label={
                        'Use Product\'s Shopify "Price" when "Compare at price" is not available'
                      }
                      name="product.MSRP.useSalePrice"
                    />
                    {values.product.MSRP.useSalePrice && (
                      <div className="use-price-adjustment">
                        <AppControlledTextField
                          label="Adjust by (+/-)"
                          helpText="The price will be adjusted by this amount"
                          name="product.MSRP.salePriceAdjustment.amount"
                          type="number"
                          placeholder="0"
                          min={0}
                          validate={validation.isOptionalNumber}
                          connectedRight={
                            <AppControlledSelect
                              name="product.MSRP.salePriceAdjustment.type"
                              options={adjustmentTypeOptions(currency)}
                              label="Adjustment type"
                              labelHidden
                            />
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </AppCollapsible>
          </AppStack>
        )}
      </AppCard>
    </AppLayoutAnnotatedSection>
  );
};
