import { XCircleIcon, ExternalIcon, ReceiptRefundIcon } from '@shopify/polaris-icons';
import fileDownload from 'js-file-download';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Decimal from 'decimal.js';
import { AppSkeletonBodyText } from '../../../../core/components/feedback-indicators/skeleton/skeleton-body-text/skeleton-body-text';
import { AppSkeletonPage } from '../../../../core/components/feedback-indicators/skeleton/skeleton-page/skeleton-page';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { DateTooltipUTC } from '../../../../core/components/overlays/date-tooltip-utc/date-tooltip-utc';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { getFullFormattedDate, getUTCDate } from '../../../../core/helpers/date.helper';
import { getShopSelector } from '../../../../core/redux/modules/auth/auth.selectors';
import { ICancelOrderLineItemsBody, ordersApi } from '../../../api/orders.api';
import {
  acceptCancellationRequestAction,
  cancelLineItemsAction,
  declineCancellationRequestAction,
  getOrderDetailsAction,
  hideAcceptingCancellationToastAction,
  hideDecliningCancellationToastAction,
  refundOrderAction,
} from '../../../redux/modules/orders/orders.actions';
import {
  acceptingCancellationSelector,
  decliningCancellationSelector,
  getOrderCancellationFetchingSelector,
  getOrderDetailsSelector,
} from '../../../redux/modules/orders/orders.selectors';
import { RootState } from '../../../redux/reducers';
import { OrderDetails } from '../../containers/order-details/order-details';
import { OrderDetailsWithTabs } from '../../containers/order-details/order-details-with-tabs';
import { OrderStatus } from '../../containers/order-status/order-status';
import { CreateCancellationModal } from '../../modals/create-cancellation-modal/create-cancellation-modal';
import { CreateRefundModal } from '../../modals/create-refund-modal/create-refund-modal';

import './order-details-layout.scss';

interface OrderDetailsLayoutProp {
  orderId?: string;
}

export function OrderDetailsLayout(props: OrderDetailsLayoutProp) {
  const { id: orderId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const shop = useSelector(getShopSelector);
  const details = useSelector((state: RootState) =>
    getOrderDetailsSelector(state as any, props.orderId || orderId),
  );

  const acceptingCancellation = useSelector(acceptingCancellationSelector);
  const decliningCancellation = useSelector(decliningCancellationSelector);
  const fetchingCancellation = useSelector(getOrderCancellationFetchingSelector);
  const [downloadingPackingSlip, setDownloadingPackingSlip] = useState(false);
  const [openCancellationModal, setOpenCancellationModal] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [invalidRefundAmount, setInvalidRefundAmount] = useState('');

  useEffect(() => {
    dispatch(getOrderDetailsAction(props.orderId || orderId));
  }, [dispatch, props.orderId, orderId]);

  const availableForRefund = useMemo(
    () =>
      details?.totals &&
      new Decimal(details.totals.total)
        .minus(details.totals.refunded || 0)
        .minus(
          details.totals.manualRefund?.reduce(
            (acc, cr) => acc.plus(cr.amount || 0),
            new Decimal(0),
          ) || 0,
        )
        .toNumber(),
    [details?.totals],
  );

  const acceptCancellationRequest = useCallback(() => {
    dispatch(acceptCancellationRequestAction(props.orderId || orderId));
  }, [dispatch, props.orderId, orderId]);

  const hideAcceptingCancellationToast = useCallback(() => {
    dispatch(hideAcceptingCancellationToastAction());
  }, [dispatch]);

  const declineCancellationRequest = useCallback(() => {
    dispatch(declineCancellationRequestAction(props.orderId || orderId));
  }, [dispatch, orderId, props.orderId]);

  const hideDecliningCancellationToast = useCallback(() => {
    dispatch(hideDecliningCancellationToastAction());
  }, [dispatch]);

  const downloadPackingSlip = useCallback(() => {
    setDownloadingPackingSlip(true);
    ordersApi
      .getOrderPackingSlip(props.orderId || orderId)
      .then(({ data }) => {
        fileDownload(data, `Packing-Slip-${props.orderId || orderId}.pdf`);
      })
      .catch(console.error)
      .finally(() => {
        setDownloadingPackingSlip(false);
      });
  }, [props.orderId, orderId]);

  const skeletonPage = useMemo(
    () => (
      <AppSkeletonPage>
        <AppCard sectioned>
          <AppSkeletonBodyText />
        </AppCard>
        <AppCard sectioned>
          <AppSkeletonBodyText />
        </AppCard>
      </AppSkeletonPage>
    ),
    [],
  );

  const pageMarkup = useMemo(
    () =>
      !details ? (
        skeletonPage
      ) : (
        <AppPage
          title={`Order ${details.name}`}
          titleMetadata={<OrderStatus status={details.payoutStatus || details.status} />}
          secondaryActions={[
            {
              content: 'Download Packing Slip',
              onAction: downloadPackingSlip,
              loading: downloadingPackingSlip,
              disabled: !details.products.filter(
                (p) => p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0) !== p.totalQty,
              ).length,
            },
            {
              content: 'View on Shopify',
              icon: ExternalIcon,
              onAction: () => {
                window.open(`https://${shop}/admin/orders/${details.shopify.platformOrderId}`);
              },
            },
            {
              content: 'Refund',
              icon: ReceiptRefundIcon,
              onAction: () => {
                setOpenRefundModal(true);
              },
              disabled:
                details.status === 'cancelled' ||
                details.paidViaCrowdship ||
                availableForRefund === 0,
            },
            {
              content: 'Create cancellation',
              icon: XCircleIcon,
              loading: fetchingCancellation,
              onAction: () => {
                setOpenCancellationModal(true);
              },
              disabled:
                fetchingCancellation ||
                !details ||
                details.products.some((p) => p.cancellations.some((c) => c.pending)) ||
                details.products.every(
                  (p) =>
                    p.totalQty ===
                    p.fulfilledQty + p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0),
                ),
            },
          ]}
        >
          <DateTooltipUTC
            date={details.createdAt}
            className="order-date"
            dateFormatter={getFullFormattedDate}
            tooltipFormatter={getUTCDate}
          />
          {details.applicableForLabelPurchase && details.status !== 'cancelled' ? (
            <OrderDetailsWithTabs
              retailer={details.retailer}
              retailerOrderName={details.retailerOrderName}
              createdAt={details.createdAt}
              shippingAddress={details.shippingAddress}
              originalShippingAddress={details?.originalShippingAddress}
              status={details.status}
              products={details.products}
              totals={details.totals}
              onAcceptCancellationRequest={acceptCancellationRequest}
              onDeclineCancellationRequest={declineCancellationRequest}
              locations={details.locations}
              orderId={props.orderId || orderId}
              requestedShipping={details.requestedShipping}
            />
          ) : (
            <OrderDetails
              retailer={details.retailer}
              retailerOrderName={details.retailerOrderName}
              shippingAddress={details.shippingAddress}
              originalShippingAddress={details?.originalShippingAddress}
              createdAt={details.createdAt}
              status={details.status}
              products={details.products}
              totals={details.totals}
              onAcceptCancellationRequest={acceptCancellationRequest}
              onDeclineCancellationRequest={declineCancellationRequest}
            />
          )}
        </AppPage>
      ),
    [
      details,
      skeletonPage,
      downloadPackingSlip,
      downloadingPackingSlip,
      availableForRefund,
      fetchingCancellation,
      acceptCancellationRequest,
      declineCancellationRequest,
      props.orderId,
      orderId,
      shop,
    ],
  );

  const handleRefundCreate = useCallback(
    (refundAmount: number, reason: string) => {
      dispatch(refundOrderAction(props.orderId || orderId, { refundAmount, reason }));
      setOpenRefundModal(false);
    },
    [dispatch, orderId, props.orderId],
  );

  const refundModalMarkup = useMemo(
    () =>
      openRefundModal ? (
        <CreateRefundModal
          availableForRefund={availableForRefund}
          orderName={details?.name || ''}
          open={openRefundModal}
          onClose={() => setOpenRefundModal(false)}
          onError={setInvalidRefundAmount}
          onCreate={handleRefundCreate}
        />
      ) : null,
    [availableForRefund, details?.name, handleRefundCreate, openRefundModal],
  );

  const downloadingPackingSlipToast = useMemo(
    () =>
      downloadingPackingSlip ? (
        <AppToast content="Downloading packing slip..." onDismiss={() => {}} />
      ) : null,
    [downloadingPackingSlip],
  );

  const acceptingCancellationToast = useMemo(
    () =>
      acceptingCancellation ? (
        <AppToast onDismiss={hideAcceptingCancellationToast} content="Accepting cancellation..." />
      ) : null,
    [acceptingCancellation, hideAcceptingCancellationToast],
  );

  const decliningCancellationToast = useMemo(
    () =>
      decliningCancellation ? (
        <AppToast onDismiss={hideDecliningCancellationToast} content="Declining cancellation..." />
      ) : null,
    [decliningCancellation, hideDecliningCancellationToast],
  );

  const incorrectRefundAmountToast = useMemo(
    () =>
      invalidRefundAmount ? (
        <AppToast
          error
          content={invalidRefundAmount}
          onDismiss={() => setInvalidRefundAmount('')}
        />
      ) : null,
    [invalidRefundAmount],
  );

  return (
    <div className="order-details-layout">
      {pageMarkup}
      {acceptingCancellationToast}
      {decliningCancellationToast}
      {downloadingPackingSlipToast}
      {incorrectRefundAmountToast}
      {refundModalMarkup}
      {details && (
        <CreateCancellationModal
          open={openCancellationModal}
          isSomeLocationUpdated={details.isSomeLocationUpdated}
          onClose={() => setOpenCancellationModal(false)}
          products={
            details
              ? details.products.filter(
                  (p) =>
                    p.totalQty !==
                    p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0) + p.fulfilledQty,
                )
              : []
          }
          onCreate={(request: ICancelOrderLineItemsBody) => {
            dispatch(
              cancelLineItemsAction(props.orderId || orderId, {
                ...request,
                lineItems: request.lineItems.filter((li) => +li.quantity),
              }),
            );
            setOpenCancellationModal(false);
          }}
        />
      )}
    </div>
  );
}
