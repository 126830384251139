import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetMarketplaceProductListFiltersAction,
  setMarketplaceProductListCategoryFilterAction,
  setMarketplaceProductListConnectionFilterAction,
  setMarketplaceProductListPropertyFilterAction,
  setMarketplaceProductListQueryFilterAction,
  setMarketplaceProductListSupplierFilterAction,
  setMarketplaceProductListTypeFilterAction,
  setMarketplaceProductListVendorFilterAction,
  clearAllMarketplaceProductListFiltersAction,
  setMarketplaceProductListInventoryFilterAction,
} from '../../../../../shopify-retailer/redux/modules/marketplace/marketplace.actions';
import {
  getMarketplaceFilterOptionsSelector,
  getMarketplaceProductViewFiltersSelector,
} from '../../../../../shopify-retailer/redux/modules/marketplace/marketplace.selectors';
import { PRODUCT_CATEGORY, PRODUCT_INVENTORY } from '../../../../constants/product.constants';
import { IAppliedFilter } from '../../../filters/filters';
import '../../product-list.scss';
import { SKULimit } from '../sku-limit';
import { CategoryFilter, CATEGORY_FILTER_KEY } from './filter-elements/category-filter';
import { HideConnectionsFilter } from './filter-elements/hide-connections-filter';
import { PropertyFilter, PROPERTY_FILTER_KEY } from './filter-elements/property-filter';
import { QueryFilterContainer } from './filter-elements/query-filter-container';
import { SupplierFilter, SUPPLIER_FILTER_KEY } from './filter-elements/supplier-filter';
import { TypeFilter, TYPE_FILTER_KEY } from './filter-elements/type-filter';
import { VendorFilter, VENDOR_FILTER_KEY } from './filter-elements/vendor-filter';
import { INVENTORY_FILTER_KEY, InventoryFilter } from './filter-elements/inventory-filter';

export const MarketplaceImportFilter = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getMarketplaceProductViewFiltersSelector);
  const { types, vendors, fulfilledBy, properties } = useSelector(
    getMarketplaceFilterOptionsSelector,
  );

  useEffect(() => {
    return () => {
      dispatch(resetMarketplaceProductListFiltersAction());
    };
  }, [dispatch]);

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedVendors = {
      key: VENDOR_FILTER_KEY,
      label: `Brands: ${filters.vendors.join(', ')}`,
      onRemove: () => dispatch(setMarketplaceProductListVendorFilterAction({ vendors: [] })),
    };
    const appliedSuppliers = {
      key: SUPPLIER_FILTER_KEY,
      label: `Suppliers: ${filters.fulfilledBy.join(', ')}`,
      onRemove: () => dispatch(setMarketplaceProductListSupplierFilterAction({ fulfilledBy: [] })),
    };
    const appliedTypes = {
      key: TYPE_FILTER_KEY,
      label: `Types: ${filters.types.join(', ')}`,
      onRemove: () => dispatch(setMarketplaceProductListTypeFilterAction({ types: [] })),
    };
    const appliedCategory = {
      key: CATEGORY_FILTER_KEY,
      label: `Category: ${filters.productCategory}`,
      onRemove: () =>
        dispatch(
          setMarketplaceProductListCategoryFilterAction({ productCategory: PRODUCT_CATEGORY.NONE }),
        ),
    };
    const appliedProperties = {
      key: PROPERTY_FILTER_KEY,
      label: `Properties: ${filters.properties.join(', ')}`,
      onRemove: () => dispatch(setMarketplaceProductListPropertyFilterAction({ properties: [] })),
    };
    const appliedInventory = {
      key: INVENTORY_FILTER_KEY,
      label: `Inventory: ${filters.productInventory}`,
      onRemove: () => {
        dispatch(
          setMarketplaceProductListInventoryFilterAction({
            productInventory: PRODUCT_INVENTORY.SHOW_ALL,
          }),
        );
      },
    };

    const chosenVendors = filters.vendors.length ? [appliedVendors] : [];
    const chosenSuppliers = filters.fulfilledBy.length ? [appliedSuppliers] : [];
    const chosenTypes = filters.types.length ? [appliedTypes] : [];
    const chosenCategory =
      filters.productCategory !== PRODUCT_CATEGORY.NONE ? [appliedCategory] : [];
    const chosenProperties = filters.properties.length ? [appliedProperties] : [];
    const chosenInventory = filters.productInventory.includes(PRODUCT_INVENTORY.SHOW_ALL)
      ? []
      : [appliedInventory];

    return [
      ...chosenVendors,
      ...chosenSuppliers,
      ...chosenTypes,
      ...chosenCategory,
      ...chosenProperties,
      ...chosenInventory,
    ];
  }, [filters, dispatch]);

  return (
    <>
      <SKULimit />
      <QueryFilterContainer
        value={filters.query}
        onChange={(query) => dispatch(setMarketplaceProductListQueryFilterAction({ query }))}
        filters={[
          VendorFilter({
            value: filters.vendors,
            onChange: (vendors) =>
              dispatch(setMarketplaceProductListVendorFilterAction({ vendors })),
            options: vendors,
          }),
          SupplierFilter({
            value: filters.fulfilledBy,
            onChange: (fulfilledBy) =>
              dispatch(setMarketplaceProductListSupplierFilterAction({ fulfilledBy })),
            options: fulfilledBy,
          }),
          TypeFilter({
            value: filters.types,
            onChange: (types) => dispatch(setMarketplaceProductListTypeFilterAction({ types })),
            options: types,
          }),
          CategoryFilter({
            value: filters.productCategory,
            onChange: (productCategory) =>
              dispatch(
                setMarketplaceProductListCategoryFilterAction({
                  productCategory: productCategory as PRODUCT_CATEGORY,
                }),
              ),
          }),
          HideConnectionsFilter({
            value: filters.noConnectedProducts,
            onChange: (noConnectedProducts) =>
              dispatch(setMarketplaceProductListConnectionFilterAction({ noConnectedProducts })),
          }),
          PropertyFilter({
            value: filters.properties,
            onChange: (properties) =>
              dispatch(setMarketplaceProductListPropertyFilterAction({ properties })),
            options: properties,
          }),
          InventoryFilter({
            value: filters.productInventory,
            onChange: (inventory) => {
              dispatch(
                setMarketplaceProductListInventoryFilterAction({
                  productInventory: inventory as PRODUCT_INVENTORY,
                }),
              );
            },
          }),
        ]}
        appliedFilters={appliedFilters}
        onClearAll={() => dispatch(clearAllMarketplaceProductListFiltersAction())}
      />
    </>
  );
};
